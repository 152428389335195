export function generateState(length = 32): string {
  const array = new Uint8Array(length);

  crypto.getRandomValues(array);

  // Convert the array to a base64 string and make it URL-safe by removing unsafe characters
  return btoa(String.fromCharCode(...array))
    .replace(/\+/g, '-') // Replace + with -
    .replace(/\//g, '_') // Replace / with _
    .replace(/=+$/, ''); // Remove any trailing =
}
