import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path d=\"M11.379 14.7004C11.6547 15.1945 12.3659 15.1935 12.6402 14.6986L15.0687 10.3174L18.5771 16.4788C18.7743 16.8251 19.2149 16.946 19.5612 16.7488C19.9075 16.5516 20.0283 16.1111 19.8312 15.7647L15.6873 8.48727C15.4087 7.99802 14.7021 8.00207 14.4292 8.49449L12.007 12.8643L9.5674 8.49268C9.29229 7.9997 8.58315 7.99936 8.30756 8.49207L5.37073 13.7509L5.65935 14.4519L6.09229 15.4002L8.9366 10.3237L11.379 14.7004Z\" fill=\"url(#paint0_linear_3386_25646)\"></path><path d=\"M5.12406 15.2063C4.27205 13.3791 4.18844 11.3332 4.84741 9.47032C5.52229 7.56251 6.92278 5.99651 8.79368 5.12409C12.5911 3.35331 17.1051 4.99625 18.8759 8.79371C19.0443 9.15488 19.4736 9.31114 19.8348 9.14272C20.1959 8.97431 20.3522 8.54499 20.1838 8.18382C18.0762 3.66402 12.7036 1.70856 8.18379 3.81618C5.95827 4.85395 4.28964 6.71978 3.4869 8.98904C2.70304 11.2049 2.80266 13.6428 3.81615 15.8162C5.92376 20.336 11.2963 22.2914 15.8161 20.1838C15.8272 20.1787 15.8382 20.1732 15.849 20.1675C17.0363 19.5385 17.9151 18.9289 18.4908 18.3229C18.6039 18.2039 18.9773 17.7816 18.9773 17.7816C18.9773 17.7816 18.5856 17.6992 18.2764 17.493C17.9671 17.2868 17.7816 16.957 17.7816 16.957C17.7816 16.957 17.5419 17.2264 17.4446 17.3289C16.996 17.8011 16.2425 18.3246 15.1898 18.8835C11.3958 20.642 6.8923 18.9983 5.12406 15.2063Z\" fill=\"url(#paint1_linear_3386_25646)\"></path><defs><linearGradient id=\"paint0_linear_3386_25646\" x1=\"5.58234\" y1=\"17.3092\" x2=\"17.3728\" y2=\"20.5472\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#F9482D\"></stop><stop offset=\"1\" stop-color=\"#FDA021\"></stop></linearGradient><linearGradient id=\"paint1_linear_3386_25646\" x1=\"21.2565\" y1=\"21.3538\" x2=\"24.8966\" y2=\"9.86691\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#13BF79\"></stop><stop offset=\"0.51206\" stop-color=\"#1BCB91\"></stop><stop offset=\"1\" stop-color=\"#0DC98D\"></stop></linearGradient></defs>", 3)
  ])))
}
export default { render: render }